import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'

import '@/common/common.css' /* 引入公共样式 */
Vue.config.productionTip = false

Vue.prototype.$axios = axios

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

<template>
  <div id="app">
    <!--  头部  -->
    <div class="header">
      <div class="header-logo" @click="goHome"></div>
      <div class="header-menu">
        <i class="header-menu-line line-1"></i>
        <i class="header-menu-line line-2"></i>
        <i class="header-menu-line line-3"></i>
      </div>
    </div>
    <!--  路由出口  -->
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'App',
  components: {
  },
  data () {
    return {}
  },
  methods: {
    // 获取随机数
    getRandom (start, end) {
      const num = end - start + 1
      return Math.floor(Math.random() * num + start)
    },
    goHome () {
      this.$router.push({
        path: '/'
      })
    }
  }
}
</script>

<style>
.header {
  width: 100%;
  height: 60px;
  background-color: #7db790;
  position: relative;
}

.header-logo {
  position: absolute;
  top: 0;
  left: 20px;
  width: 125px;
  height: 60px;
  vertical-align: center;
  background-image: url("../src/assets/img/logo.svg");
  background-repeat: no-repeat;
  background-position: left 0 top 12px;
  background-size: 100%;
  float: left;
}

.header-menu {
  position: absolute;
  top: 15px;
  right: 20px;
  width: 40px;
  height: 30px;
  float: right;
  background-color: #333;
  box-sizing: border-box;
  border: 1px #fff solid;
  border-radius: 3px;
}

.header-menu-line {
  position: absolute;
  width: 20px;
  height: 2px;
  background-color: #fff;
  border-radius: 1px;
}

.line-1 {
  top: 6px;
  left: 50%;
  transform: translateX(-50%);
}

.line-2 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.line-3 {
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
